export const API_URL = 'https://order.ellaapp.com/admin/web/v1/';
export const API_URL2 = 'https://order.ellaapp.com/admin/web/';
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const timer_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://bytekiosk.bytesstation.com/kioskautologin/30b1a711dec4bd62b2b9ac89f7bfb1da/ddcc3dddaf728398f49991bd0b3cb9a9";
export const   settlement_timer = 20;
export const apitimeout  = 29000;
export const cart_time = 30;
export const payment_failed_modal = 10;
export const   page_redirection_time = 30;
export const   enable_test_buttons = false;
export const terminalCheck = 10000;
export const terminal_check_enable = true;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
export const   payment_page_redirection_time = 60;
export const   paymentapitimeout = 60000;
export const qr_page_redirection_time = 60;
export const qr_page_retry_modal_time = 15;
export const dbs_qr_timer = 120;
export const dbs_qr_timer_res_wait = 60;
export const apiSigningKey = 'ad8ce5d5d98a1a5fd5cb772aeb5a93dc';
